import React, { useEffect, useState } from 'react'
import { Layout } from '@/components/layouts/Layout'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { theme } from '@/styles/theme'
import { useDispatch, useSelector } from 'react-redux'
import { setContactPhoneChange } from '@/store/contactPhoneChange/action'
import { ContactPhoneChange } from '@/store/contactPhoneChange/types'
import { RootState } from '@/store/index'
import { navigate } from 'gatsby'
import { STATUS } from '@/constants/status'
import useFormInput from '@/hooks/useFormInput'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'

import { useFileValidation } from '@/hooks/useFileValidation'

import { Seo } from '@/components/Seo'

import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import {
  FormSubItem,
  FormItemAlert,
  FormRadio,
  FormSubItemInner,
  FormDatePicker,
  FormMain,
  FormAction,
  Caution,
  FormHeader,
  FormContent,
  FormSubGroup,
} from '@/styles/form'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { Button } from '@/components/elements/Button'
import { FormFooter } from '@/components/layouts/Form/FormFooter'
import { FormLabel } from '@/components/layouts/Form/FormLabel'
import { FormSubLabel } from '@/components/layouts/Form/FormSubLabel'
import { FormFileUpload } from '@/components/layouts/Form/FormFileUpload'
import { FormDateSelect } from '@/components/layouts/Form/FormDateSelect'
import { FormInput } from '@/components/layouts/Form/FormInput'
import { Link } from '@/components/elements/Link'

export default function phoneChange(): JSX.Element {
  const breadCrumbs: {
    name: string
    path?: string
  }[] = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'お問い合わせ',
      path: '/contact/',
    },
    {
      name: '電話番号変更 申請フォーム',
    },
  ]

  const dispatch = useDispatch()
  const form = useSelector((state: RootState) => state.contactPhoneChange)
  const [newAccountStatus, setNewAccountStatus] = useState<string>('')
  const [bankStatus, setBankStatus] = useState<string>('')
  const [chargeHistoryStatus, setChargeHistoryStatus] = useState<string>('')
  const [paymentHistoryStatus, setPaymentHistoryStatus] = useState<string>('')
  const [supayBalanceStatus, setSupayBalanceStatus] = useState<string>('')
  const [profileStatus, setProfileStatus] = useState<string>('')

  const { sliceMaxLength, formRadioClick } = useFormInput()
  registerLocale('ja', ja)
  const today = new Date()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
    control,
  } = useForm<ContactPhoneChange>()

  const fileValidation = useFileValidation<ContactPhoneChange>(
    form,
    setValue,
    errors,
    clearErrors
  )
  const { files, otherFiles, fileErrors, setFileErrors } = fileValidation

  const onSubmit = (data: ContactPhoneChange) => {
    data.require_files = [...files].filter((file) => file)
    data.other_files = [...otherFiles].filter((file) => file)
    dispatch(setContactPhoneChange(data))
    navigate('/contact/phone-change/confirm/')
  }

  const setStatus = () => {
    setNewAccountStatus(form.new_account_status)
    setBankStatus(form.bank_status)
    setChargeHistoryStatus(form.charge_history_status)
    setPaymentHistoryStatus(form.payment_history_status)
    setSupayBalanceStatus(form.supay_balance_status)
    setProfileStatus(form.profile_status)
  }

  useEffect(() => {
    setStatus()
  }, [])

  return (
    <Layout isForm>
      <Seo
        title="電話番号変更申請 - お問い合わせ"
        canonical="https://su-pay.jp/contact/phone-change/"
        description="電話番号を変更される場合は、こちらからお手続きをお願いします。"
      />
      <Title as="h1" bg={true}>
        お問い合わせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            電話番号変更 申請フォーム
          </Title>
        </TitleWrapper>
        <FormHeader color="blue">
          <Caution>
            <div>
              <p>
                電話番号変更のお手続きを行います。
                以下をご確認のうえ、必要情報を入力してください。
              </p>
            </div>
            <p>【注意事項】</p>
            <p>次の項目が必須項目になります。事前にご準備ください。</p>
            <ul>
              <li>メールアドレス(フォーム入力後のご連絡のため)</li>
              <li>移行先の電話番号(新しい携帯電話番号)</li>
              <li>移行元の電話番号(旧携帯電話番号)</li>
            </ul>
            <p>
              以下の項目はわかる範囲で入力していただくとスムーズに移行ができます。
            </p>
            <ul>
              <li>
                SU-PAYアカウント登録情報/チャージや決済などの取引情報(ご本人様確認情報)
              </li>
            </ul>
            <div>
              <p>※</p>
              <p>
                迷惑メール防止のためにドメイン指定受信をしている方は、あらかじめ設定を解除するか「@su-pay.jp」と「@trial-net.co.jp」の受信設定をお願いします。
              </p>
            </div>
          </Caution>
        </FormHeader>
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <FormMain>
            <FormLabel isRequired>ご連絡先メールアドレス</FormLabel>
            <FormContent>
              <FormInput
                defaultValue={form.email}
                type="email"
                id="email"
                placeholder="youremail@example.com"
                maxLength={255}
                register={register('email', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                  pattern: {
                    message: 'メールアドレスを正しい形式で入力してください',
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                })}
                errorMessage={errors.email}
              />
            </FormContent>
            <FormLabel isRequired>
              移行先の電話番号(新しい携帯電話番号)の情報
            </FormLabel>
            <FormContent>
              <FormSubGroup>
                <FormSubItem>
                  <FormSubLabel htmlFor="new_phone">
                    移行先の電話番号(新しい携帯電話番号)
                  </FormSubLabel>
                  <FormInput
                    defaultValue={form.new_phone}
                    type="tel"
                    id="new_phone"
                    placeholder="0123456789"
                    maxLength={15}
                    register={register('new_phone', {
                      required: {
                        value: true,
                        message: '入力してください。',
                      },
                      pattern: {
                        message: '電話番号を正しい形式で入力してください',
                        value: /^[0-9]+$/,
                      },
                    })}
                    errorMessage={errors.new_phone}
                    hintText={['ハイフンなし']}
                  />
                </FormSubItem>
                <FormSubItem>
                  <FormSubLabel>
                    移行先の電話番号(新しい携帯電話番号)でのSU-PAYアカウント作成状況
                  </FormSubLabel>
                  <FormSubItemInner>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.new_account_status === STATUS.HAD}
                        type="radio"
                        value={STATUS.HAD}
                        id="new_account_status_yes"
                        {...register('new_account_status', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        name="new_account_status"
                        onChange={(e) => {
                          setNewAccountStatus(e.target.value)
                          setValue('new_account_status', e.target.value)
                          trigger('new_account_status')
                        }}
                      />
                      <label htmlFor="new_account_status_yes">作成済み</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.new_account_status === STATUS.NONE}
                        type="radio"
                        value={STATUS.NONE}
                        id="new_account_status_no"
                        {...register('new_account_status')}
                        name="new_account_status"
                        onChange={(e) => {
                          setNewAccountStatus(e.target.value)
                          setValue('new_account_status', e.target.value)
                          trigger('new_account_status')
                        }}
                      />
                      <label htmlFor="new_account_status_no">未作成</label>
                    </FormRadio>
                  </FormSubItemInner>
                  <ErrorMessage
                    errors={errors}
                    name="new_account_status"
                    render={({ message }) => (
                      <FormItemAlert>{message}</FormItemAlert>
                    )}
                  />
                </FormSubItem>
                {newAccountStatus === STATUS.HAD && (
                  <>
                    <FormSubItem>
                      <FormSubLabel htmlFor="new_memberid">
                        会員番号
                      </FormSubLabel>
                      <FormInput
                        defaultValue={form.new_memberid}
                        type="number"
                        id="new_memberid"
                        onInput={(e) => sliceMaxLength(e, 13)}
                        register={register('new_memberid', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                          pattern: {
                            value: /^\d{13}$/,
                            message:
                              '会員番号は半角数字(13桁)で入力してください',
                          },
                        })}
                        errorMessage={errors.new_memberid}
                        hintText={[
                          '半角数字',
                          '不明な場合は0を13桁ご入力ください。',
                        ]}
                      />
                    </FormSubItem>
                  </>
                )}
              </FormSubGroup>
            </FormContent>
            <FormLabel isRequired>
              移行元の電話番号(旧携帯電話番号)のSU-PAYアカウント情報
            </FormLabel>
            <FormContent>
              <FormSubGroup>
                <FormSubItem>
                  <FormSubLabel htmlFor="old_phone">
                    移行元の電話番号(旧携帯電話番号)
                  </FormSubLabel>
                  <FormInput
                    defaultValue={form.old_phone}
                    type="tel"
                    id="old_phone"
                    placeholder="0123456789"
                    maxLength={15}
                    register={register('old_phone', {
                      required: {
                        value: true,
                        message: '入力してください。',
                      },
                      pattern: {
                        message: '電話番号を正しい形式で入力してください',
                        value: /^[0-9]+$/,
                      },
                    })}
                    errorMessage={errors.old_phone}
                    hintText={['ハイフンなし']}
                  />
                </FormSubItem>
                <FormSubItem>
                  <FormSubLabel htmlFor="old_memberid">会員番号</FormSubLabel>
                  <FormInput
                    defaultValue={form.old_memberid}
                    type="number"
                    id="old_memberid"
                    onInput={(e) => sliceMaxLength(e, 13)}
                    register={register('old_memberid', {
                      required: {
                        value: true,
                        message: '入力してください。',
                      },
                      pattern: {
                        value: /^\d{13}$/,
                        message: '会員番号は半角数字(13桁)で入力してください',
                      },
                    })}
                    errorMessage={errors.old_memberid}
                    hintText={[
                      '半角数字',
                      <>
                        会員番号のご確認方法は
                        <Link
                          link="/faq/profile/y1f5y53p0ip/"
                          text="こちら"
                          isExternal
                          isForm
                        />
                      </>,
                      '不明な場合は0を13桁ご入力ください。',
                    ]}
                  />
                </FormSubItem>
                <FormSubItem>
                  <FormSubLabel>銀行口座登録情報</FormSubLabel>
                  <FormSubItemInner>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.bank_status === STATUS.HAD}
                        type="radio"
                        id="bank_yes"
                        value={STATUS.HAD}
                        {...register('bank_status', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setBankStatus(e.target.value)
                          setValue('bank_status', e.target.value)
                          trigger('bank_status')
                        }}
                      />
                      <label htmlFor="bank_yes">あり</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.bank_status === STATUS.NONE}
                        type="radio"
                        id="bank_no"
                        value={STATUS.NONE}
                        {...register('bank_status')}
                        onChange={(e) => {
                          setBankStatus(e.target.value)
                          setValue('bank_status', e.target.value)
                          trigger('bank_status')
                        }}
                      />
                      <label htmlFor="bank_no">なし</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.bank_status === STATUS.NOT_SURE}
                        type="radio"
                        id="bank_not_sure"
                        value={STATUS.NOT_SURE}
                        {...register('bank_status')}
                        onChange={(e) => {
                          setBankStatus(e.target.value)
                          setValue('bank_status', e.target.value)
                          trigger('bank_status')
                        }}
                      />
                      <label htmlFor="bank_not_sure">わからない</label>
                    </FormRadio>
                  </FormSubItemInner>
                  <ErrorMessage
                    errors={errors}
                    name="bank_status"
                    render={({ message }) => (
                      <FormItemAlert>{message}</FormItemAlert>
                    )}
                  />
                </FormSubItem>
                {bankStatus === STATUS.HAD && (
                  <>
                    <FormSubItem>
                      <FormSubLabel htmlFor="bank_name">
                        金融機関名
                      </FormSubLabel>
                      <FormInput
                        defaultValue={form.bank_name}
                        type="text"
                        id="bank_name"
                        maxLength={255}
                        register={register('bank_name', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        errorMessage={errors.bank_name}
                      />
                    </FormSubItem>
                    <FormSubItem>
                      <FormSubLabel htmlFor="branch_name">支店名</FormSubLabel>
                      <FormInput
                        defaultValue={form.branch_name}
                        type="text"
                        id="branch_name"
                        maxLength={255}
                        register={register('branch_name', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        errorMessage={errors.branch_name}
                      />
                    </FormSubItem>
                  </>
                )}
              </FormSubGroup>
            </FormContent>
            <FormLabel isRequired>最新のチャージ履歴</FormLabel>
            <FormContent>
              <FormSubGroup>
                <FormSubItem>
                  <FormSubItemInner>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.charge_history_status === STATUS.HAD
                        }
                        type="radio"
                        id="charge_history_yes"
                        value={STATUS.HAD}
                        {...register('charge_history_status', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setChargeHistoryStatus(e.target.value)
                          setValue('charge_history_status', e.target.value)
                          trigger('charge_history_status')
                        }}
                      />
                      <label htmlFor="charge_history_yes">あり</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.charge_history_status === STATUS.NONE
                        }
                        type="radio"
                        id="charge_history_no"
                        value={STATUS.NONE}
                        {...register('charge_history_status')}
                        onChange={(e) => {
                          setChargeHistoryStatus(e.target.value)
                          setValue('charge_history_status', e.target.value)
                          trigger('charge_history_status')
                        }}
                      />
                      <label htmlFor="charge_history_no">なし</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.charge_history_status === STATUS.NOT_SURE
                        }
                        type="radio"
                        id="charge_history_not_sure"
                        value={STATUS.NOT_SURE}
                        {...register('charge_history_status')}
                        onChange={(e) => {
                          setChargeHistoryStatus(e.target.value)
                          setValue('charge_history_status', e.target.value)
                          trigger('charge_history_status')
                        }}
                      />
                      <label htmlFor="charge_history_not_sure">
                        わからない
                      </label>
                    </FormRadio>
                  </FormSubItemInner>
                  <ErrorMessage
                    errors={errors}
                    name="charge_history_status"
                    render={({ message }) => (
                      <FormItemAlert>{message}</FormItemAlert>
                    )}
                  />
                </FormSubItem>
                {chargeHistoryStatus === STATUS.HAD && (
                  <>
                    <FormSubItem>
                      <FormSubLabel>チャージ日</FormSubLabel>
                      <FormDatePicker>
                        <Controller
                          control={control}
                          name="charge_history_date"
                          defaultValue={form.charge_history_date}
                          rules={{
                            required: {
                              value: true,
                              message: '入力してください。',
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <ReactDatePicker
                              onChange={onChange}
                              showTimeInput
                              timeInputLabel="時刻:"
                              selected={value}
                              locale="ja"
                              dateFormat="yyyy/MM/dd HH:mm"
                              maxDate={today}
                              dateFormatCalendar={'yyyy年 MM月'}
                              calendarStartDay={1}
                            />
                          )}
                        />
                      </FormDatePicker>
                      <FormDateSelect<ContactPhoneChange>
                        fieldName="charge_history_date"
                        setValue={setValue}
                        defaultValue={form.charge_history_date}
                        isRequiredTime
                      />
                      <ErrorMessage
                        errors={errors}
                        name="charge_history_date"
                        render={({ message }) => (
                          <FormItemAlert>{message}</FormItemAlert>
                        )}
                      />
                    </FormSubItem>
                    <FormSubItem>
                      <FormSubLabel htmlFor="charge_history_money">
                        チャージ金額（円）
                      </FormSubLabel>
                      <FormInput
                        defaultValue={form.charge_history_money}
                        type="number"
                        id="charge_history_money"
                        onInput={(e) => sliceMaxLength(e, 10)}
                        register={register('charge_history_money', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: '半角数字で入力してください',
                          },
                        })}
                        errorMessage={errors.charge_history_money}
                        hintText={['半角数字']}
                      />
                    </FormSubItem>
                  </>
                )}
              </FormSubGroup>
            </FormContent>
            <FormLabel isRequired>決済履歴</FormLabel>
            <FormContent>
              <FormSubGroup>
                <FormSubItem>
                  <FormSubItemInner>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.payment_history_status === STATUS.HAD
                        }
                        type="radio"
                        id="payment_history_yes"
                        value={STATUS.HAD}
                        {...register('payment_history_status', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setPaymentHistoryStatus(e.target.value)
                          setValue('payment_history_status', e.target.value)
                          trigger('payment_history_status')
                        }}
                      />
                      <label htmlFor="payment_history_yes">あり</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.payment_history_status === STATUS.NONE
                        }
                        type="radio"
                        id="payment_history_no"
                        value={STATUS.NONE}
                        {...register('payment_history_status', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setPaymentHistoryStatus(e.target.value)
                          setValue('payment_history_status', e.target.value)
                          trigger('payment_history_status')
                        }}
                      />
                      <label htmlFor="payment_history_no">なし</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.payment_history_status === STATUS.NOT_SURE
                        }
                        type="radio"
                        id="payment_history_not_sure"
                        value={STATUS.NOT_SURE}
                        {...register('payment_history_status', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setPaymentHistoryStatus(e.target.value)
                          setValue('payment_history_status', e.target.value)
                          trigger('payment_history_status')
                        }}
                      />
                      <label htmlFor="payment_history_not_sure">
                        わからない
                      </label>
                    </FormRadio>
                  </FormSubItemInner>
                  <ErrorMessage
                    errors={errors}
                    name="payment_history_status"
                    render={({ message }) => (
                      <FormItemAlert>{message}</FormItemAlert>
                    )}
                  />
                </FormSubItem>
                {paymentHistoryStatus === STATUS.HAD && (
                  <>
                    <FormSubItem>
                      <FormSubLabel>決済日</FormSubLabel>
                      <FormDatePicker>
                        <Controller
                          control={control}
                          name="payment_history_date"
                          defaultValue={form.payment_history_date}
                          rules={{
                            required: {
                              value: true,
                              message: '入力してください。',
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <ReactDatePicker
                              onChange={onChange}
                              showTimeInput
                              timeInputLabel="時刻:"
                              selected={value}
                              locale="ja"
                              dateFormat="yyyy/MM/dd HH:mm"
                              maxDate={today}
                              dateFormatCalendar={'yyyy年 MM月'}
                              calendarStartDay={1}
                            />
                          )}
                        />
                      </FormDatePicker>
                      <FormDateSelect<ContactPhoneChange>
                        fieldName="payment_history_date"
                        setValue={setValue}
                        defaultValue={form.payment_history_date}
                        isRequiredTime
                      />
                      <ErrorMessage
                        errors={errors}
                        name="payment_history_date"
                        render={({ message }) => (
                          <FormItemAlert>{message}</FormItemAlert>
                        )}
                      />
                    </FormSubItem>
                    <FormSubItem>
                      <FormSubLabel htmlFor="payment_history_money">
                        決済金額（円）
                      </FormSubLabel>
                      <FormInput
                        defaultValue={form.payment_history_money}
                        type="number"
                        id="payment_history_money"
                        onInput={(e) => sliceMaxLength(e, 10)}
                        register={register('payment_history_money', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: '半角数字で入力してください',
                          },
                        })}
                        errorMessage={errors.charge_history_money}
                        hintText={['半角数字']}
                      />
                    </FormSubItem>
                    <FormSubItem>
                      <FormSubLabel htmlFor="payment_history_store">
                        利用店舗
                      </FormSubLabel>
                      <FormInput
                        defaultValue={form.payment_history_store}
                        type="text"
                        id="payment_history_store"
                        maxLength={255}
                        register={register('payment_history_store', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        errorMessage={errors.payment_history_store}
                      />
                    </FormSubItem>
                  </>
                )}
              </FormSubGroup>
            </FormContent>
            <FormLabel isRequired>現在のSU-PAY残高</FormLabel>
            <FormContent>
              <FormSubGroup>
                <FormSubItem>
                  <FormSubItemInner>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.supay_balance_status === STATUS.HAD
                        }
                        type="radio"
                        id="supay_balance_yes"
                        value={STATUS.HAD}
                        {...register('supay_balance_status', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setSupayBalanceStatus(e.target.value)
                          setValue('supay_balance_status', e.target.value)
                          trigger('supay_balance_status')
                        }}
                      />
                      <label htmlFor="supay_balance_yes">あり</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.supay_balance_status === STATUS.NONE
                        }
                        type="radio"
                        id="supay_balance_no"
                        value={STATUS.NONE}
                        {...register('supay_balance_status')}
                        onChange={(e) => {
                          setSupayBalanceStatus(e.target.value)
                          setValue('supay_balance_status', e.target.value)
                          trigger('supay_balance_status')
                        }}
                      />
                      <label htmlFor="supay_balance_no">なし</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={
                          form.supay_balance_status === STATUS.NOT_SURE
                        }
                        type="radio"
                        id="supay_balance_not_sure"
                        value={STATUS.NOT_SURE}
                        {...register('supay_balance_status')}
                        onChange={(e) => {
                          setSupayBalanceStatus(e.target.value)
                          setValue('supay_balance_status', e.target.value)
                          trigger('supay_balance_status')
                        }}
                      />
                      <label htmlFor="supay_balance_not_sure">わからない</label>
                    </FormRadio>
                  </FormSubItemInner>
                  <ErrorMessage
                    errors={errors}
                    name="supay_balance_status"
                    render={({ message }) => (
                      <FormItemAlert>{message}</FormItemAlert>
                    )}
                  />
                </FormSubItem>
                {supayBalanceStatus === STATUS.HAD && (
                  <>
                    <FormSubItem>
                      <FormSubLabel htmlFor="supay_balance">
                        SU-PAY残高
                      </FormSubLabel>
                      <FormInput
                        defaultValue={form.supay_balance}
                        type="number"
                        id="supay_balance"
                        onInput={(e) => sliceMaxLength(e, 10)}
                        register={register('supay_balance', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: '半角数字で入力してください',
                          },
                        })}
                        errorMessage={errors.supay_balance}
                        hintText={['半角数字']}
                      />
                    </FormSubItem>
                  </>
                )}
              </FormSubGroup>
            </FormContent>
            <FormLabel isRequired>個人情報（名前、生年月日等）の登録</FormLabel>
            <FormContent>
              <FormSubGroup>
                <FormSubItem>
                  <FormSubItemInner>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.profile_status === STATUS.HAD}
                        type="radio"
                        id="profile_yes"
                        value={STATUS.HAD}
                        {...register('profile_status', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        onChange={(e) => {
                          setProfileStatus(e.target.value)
                          setValue('profile_status', e.target.value)
                          trigger('profile_status')
                        }}
                      />
                      <label htmlFor="profile_yes">あり</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.profile_status === STATUS.NONE}
                        type="radio"
                        id="profile_no"
                        value={STATUS.NONE}
                        {...register('profile_status')}
                        onChange={(e) => {
                          setProfileStatus(e.target.value)
                          setValue('profile_status', e.target.value)
                          trigger('profile_status')
                        }}
                      />
                      <label htmlFor="profile_no">なし</label>
                    </FormRadio>
                    <FormRadio onClick={formRadioClick}>
                      <input
                        defaultChecked={form.profile_status === STATUS.NOT_SURE}
                        type="radio"
                        id="profile_not_sure"
                        value={STATUS.NOT_SURE}
                        {...register('profile_status')}
                        onChange={(e) => {
                          setProfileStatus(e.target.value)
                          setValue('profile_status', e.target.value)
                          trigger('profile_status')
                        }}
                      />
                      <label htmlFor="profile_not_sure">わからない</label>
                    </FormRadio>
                  </FormSubItemInner>
                  <ErrorMessage
                    errors={errors}
                    name="profile_status"
                    render={({ message }) => (
                      <FormItemAlert>{message}</FormItemAlert>
                    )}
                  />
                </FormSubItem>
                {profileStatus === STATUS.HAD && (
                  <>
                    <FormSubItem>
                      <FormSubLabel htmlFor="profile_name">名前</FormSubLabel>
                      <FormInput
                        defaultValue={form.profile_name}
                        type="text"
                        id="profile_name"
                        maxLength={255}
                        register={register('profile_name', {
                          required: {
                            value: true,
                            message: '入力してください。',
                          },
                        })}
                        errorMessage={errors.profile_name}
                      />
                    </FormSubItem>
                    <FormSubItem>
                      <FormSubLabel>生年月日</FormSubLabel>
                      <FormDatePicker>
                        <Controller
                          control={control}
                          name="profile_birthday"
                          defaultValue={form.profile_birthday}
                          rules={{
                            required: {
                              value: true,
                              message: '入力してください。',
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <ReactDatePicker
                              onChange={onChange}
                              selected={value}
                              locale="ja"
                              dateFormat="yyyy/MM/dd"
                              maxDate={today}
                              dateFormatCalendar={'yyyy年 MM月'}
                              calendarStartDay={1}
                            />
                          )}
                        />
                      </FormDatePicker>
                      <FormDateSelect<ContactPhoneChange>
                        fieldName="profile_birthday"
                        setValue={setValue}
                        defaultValue={form.profile_birthday}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="profile_birthday"
                        render={({ message }) => (
                          <FormItemAlert>{message}</FormItemAlert>
                        )}
                      />
                    </FormSubItem>
                  </>
                )}
              </FormSubGroup>
            </FormContent>
            <FormFileUpload
              registerRequire={register('require_files', {
                required: {
                  value: true,
                  message: '選択してください。',
                },
              })}
              errorMessageRequire={errors.require_files}
              fileValidation={fileValidation}
            />
          </FormMain>
          <FormFooter />
          <FormAction>
            <Button
              showArrowRight
              type="submit"
              onClick={() => {
                fileErrors.length > 0 && setFileErrors([])
              }}
            >
              確認画面へ
            </Button>
          </FormAction>
        </form>
      </MainWrapper>
    </Layout>
  )
}
